<template>
	<el-dialog title="更多搜索条件" top="1vh" width="92%" :visible.sync="is_show_in_page">
		
		<el-form class="form" label-width="90px">
			<div class="big_tit" style="margin:0">运单编号相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运单编号" style="width:340px">
					<el-input v-model="form_in_page.truck_tord_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="上游编号" style="width:340px">
					<el-input v-model="form_in_page.out_trade_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="线路编号" style="width:340px">
					<el-input v-model="form_in_page.bl_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:340px">
					<el-input v-model="form_in_page.group1_num" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >货主相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="手机号码" style="width:340px">
					<el-input v-model="form_in_page.shipper_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主姓名" style="width:340px">
					<el-input v-model="form_in_page.shipper_user_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="公司名称" style="width:340px">
					<el-input v-model="form_in_page.shipper_company_name" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >收款人/司机/车辆</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人手机" style="width:340px">
					<el-input v-model="form_in_page.truck_owner_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款人姓名" style="width:340px">
					<el-input v-model="form_in_page.truck_owner_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机手机" style="width:340px">
					<el-input v-model="form_in_page.driver_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机姓名" style="width:340px">
					<el-input v-model="form_in_page.driver_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车牌号" style="width:340px">
					<el-input v-model="form_in_page.truck_plate_num" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >状态相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运输状态" style="width:340px">
					<el-select v-model="form_in_page.status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未发车" value="1"></el-option>
						<el-option label="已发车" value="2"></el-option>
						<el-option label="已到货" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="锁定状态" style="width:340px">
					<el-select v-model="form_in_page.lock_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="已锁定" value="1"></el-option>
						<el-option label="未锁定" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付状态" style="width:340px">
					<el-select v-model="form_in_page.payed_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未支付" value="1"></el-option>
						<el-option label="部分支付" value="2"></el-option>
						<el-option label="支付完毕" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="开票状态" style="width:340px">
					<el-select v-model="form_in_page.invoiced_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未开票" value="1"></el-option>
						<el-option label="部分开票" value="2"></el-option>
						<el-option label="开票完毕" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算状态" style="width:340px">
					<el-select v-model="form_in_page.settled_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未结算" value="1"></el-option>
						<el-option label="部分结算" value="2"></el-option>
						<el-option label="结算完毕" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="上报状态" style="width:340px">
					<el-select v-model="form_in_page.upl_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未上报" value="1"></el-option>
						<el-option label="运单上报成功" value="2"></el-option>
						<el-option label="运单上报失败" value="3"></el-option>
						<el-option label="流水上报成功" value="4"></el-option>
						<el-option label="流水上报失败" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="上报目标" style="width:340px">
					<el-select v-model="form_in_page.upl_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option 
							v-for="(cname,ename) of logink_aim_list" 
							:key="ename" :label="cname" :value="ename"
						></el-option>
					</el-select>
				</el-form-item>
			</div>

			<div class="big_tit" >货源数据相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:340px">
					<el-input v-model="form_in_page.cargo_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物类型" style="width:340px">
					<el-select v-model="form_in_page.cargo_type" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option v-for="(item,index) in cargo_type" :key="index" :label="item" :value="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算方式" style="width:340px">
					<el-select v-model="form_in_page.settlement_type" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="按吨结算" value="1"></el-option>
						<el-option label="按方结算" value="2"></el-option>
						<el-option label="按趟结算" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="是否回程" style="width:340px">
					<el-select v-model="form_in_page.is_need_return" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="需要回程" value="1"></el-option>
						<el-option label="不需回程" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="匹配方式" style="width:340px">
					<el-select v-model="form_in_page.match_way" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="收款人抢单" value="1"></el-option>
						<el-option label="指定后收款人接单" value="2"></el-option>
						<el-option label="收款人竞价中标" value="3"></el-option>
						<el-option label="收款人扫码接单" value="4"></el-option>
						<el-option label="网点线下匹配" value="5"></el-option>
						<el-option label="合作方匹配" value="6"></el-option>
						<el-option label="后台匹配" value="7"></el-option>
						<el-option label="后台添加" value="8"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="添加网点" style="width:340px">
					<el-select v-model="form_in_page.outlets_num" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option v-for="(item,index) in outlets_list" :key="index" :label="item.name" :value="item.outlets_num"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货车类型" style="width:340px">
					<el-cascader 
						v-model="form_in_page.need_truck_types" 
						@change="ser_para_need_truck_types_change" 
						filterable style="width: 100%;" 
						:options="truck_type" 
					></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="装货地" style="width:340px">
					<el-cascader 
						v-model="form_in_page.case_cities" 
						@change="ser_para_case_cities_change" 
						placeholder="装货地省市区" filterable 
						style="width: 100%;" 
						:options="regionData_in_page" 
					></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货地" style="width:340px">
					<el-cascader 
						v-model="form_in_page.aim_cities" 
						@change="ser_para_aim_cities_change" 
						placeholder="到货地省市区" filterable 
						style="width: 100%;" 
						:options="regionData_in_page" 
					></el-cascader>
				</el-form-item>
			</div>

			<div class="big_tit" >时效相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货源创建" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.cargo_creat_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单成交" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.creat_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="发到时间" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.start_time_start" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货时间" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.arrived_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付完成" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.payed_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="开票完成" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.invoiced_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算完成" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.settled_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单上报" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.tord_upld_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="流水上报" style="width:340px">
					<el-date-picker 
						v-model="form_in_page.outcash_upld_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">搜索</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	import {regionData,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		props:{
			is_show:Number,//是否显示
			form:Object,//表单
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form_in_page:{

					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号

					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称

					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号

					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)

					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:收款人抢单,2:货主指定后收款人接单,3:收款人竞价,4:收款人扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time_start:null,//发车时间
					arrived_time_end:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间
				},

				//网点列表
				outlets_list:[],

				//货物类型
				cargo_type:[],

				//货车列表
				truck_type:[],

				//城市列表
				regionData_in_page:regionData,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//显示界面
					this.is_show_in_page=true;

					//读取网点列表
					this.get_outlets_list();

					//读取发货参数
					this.get_creat_para();

					//置入外部表单数据
					for(var key in this.form){
						this.form_in_page[key]=this.form[key]
					}
					
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {

			//租用货车类型选择器
			ser_para_need_truck_types_change(event){
				this.form_in_page.need_truck_type=event[0];
				this.form_in_page.need_truck_type2=event[0];
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form_in_page.case_prov=CodeToText[event[0]];
				this.form_in_page.case_city=CodeToText[event[1]];
				this.form_in_page.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form_in_page.aim_prov=CodeToText[event[0]];
				this.form_in_page.aim_city=CodeToText[event[1]];
				this.form_in_page.aim_county=CodeToText[event[2]];
			},
			
			//提交
			sub(){
				console.log(this.form_in_page)
				//通知
				this.$emit('sub',this.form_in_page)
			},

			//清空查询条件
			clear(){
				this.$emit('close',1)
				this.form_in_page={
					
					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号

					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称

					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号

					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)

					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:收款人抢单,2:货主指定后收款人接单,3:收款人竞价,4:收款人扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time_start:null,//发车时间
					arrived_time_end:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间
				}
			},

			//读取网点列表
			get_outlets_list(){
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{
						
						//渲染
						this.outlets_list=data.list
					}
				})
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					this.cargo_type=creat_para.cargo_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
						this.cargo_type=data.cargo_type
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	.el_form_item{
		margin:15px 0 0 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		// padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.bottom_btns{
		text-align: center;
		width: 100%;
		padding-top: 15px;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		padding-bottom: 30px;
		overflow-y:auto;
		position: relative;
    	top: -20px;
	}
</style>